define("discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "discourse/plugins/discourse-locations/discourse/components/location-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _I18n, _locationForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomWizardFieldLocationComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked], function () {
      return null;
    }))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "street", [_tracking.tracked], function () {
      return null;
    }))();
    #street = (() => (dt7948.i(this, "street"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "postalcode", [_tracking.tracked], function () {
      return null;
    }))();
    #postalcode = (() => (dt7948.i(this, "postalcode"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "city", [_tracking.tracked], function () {
      return null;
    }))();
    #city = (() => (dt7948.i(this, "city"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "countrycode", [_tracking.tracked], function () {
      return null;
    }))();
    #countrycode = (() => (dt7948.i(this, "countrycode"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "geoLocation", [_tracking.tracked], function () {
      return {
        lat: "",
        lon: ""
      };
    }))();
    #geoLocation = (() => (dt7948.i(this, "geoLocation"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "rawLocation", [_tracking.tracked], function () {
      return null;
    }))();
    #rawLocation = (() => (dt7948.i(this, "rawLocation"), void 0))();
    context = this.args.wizard.id;
    includeGeoLocation = true;
    inputFieldsEnabled = true;
    layoutName = "javascripts/wizard/templates/components/wizard-field-location";
    showType = true;
    constructor() {
      super(...arguments);
      const existing1 = this.args.field.value || {};
      const inputFields1 = this.inputFields;
      inputFields1.forEach(f1 => {
        if (existing1[f1]) {
          this[f1] = existing1[f1];
        }
      });
      this.geoLocation = existing1["geo_location"] || {};
      this.args.field.customCheck = this.customCheck.bind(this);
    }
    customCheck() {
      const required1 = this.required;
      const hasInput1 = this.inputFields.some(f1 => this[f1]);
      if (required1 || hasInput1) {
        return this.handleValidation();
      } else {
        return true;
      }
    }
    get inputFields() {
      return this.siteSettings.location_input_fields.split("|");
    }
    static #_9 = (() => dt7948.n(this.prototype, "inputFields", [_object.computed]))();
    handleValidation() {
      let location1 = {};
      if (this.inputFieldsEnabled && this.inputFields.indexOf("coordinates") > -1 && (this.geoLocation.lat || this.geoLocation.lon)) {
        return this.setValidation(this.geoLocation.lat && this.geoLocation.lon, "coordinates");
      }
      if (this.inputFieldsEnabled) {
        let validationType1 = null;
        this.inputFields.some(field1 => {
          const input1 = this[`${field1}`];
          if (!input1 || input1.length < 2) {
            validationType1 = field1;
            return true;
          } else {
            location1[field1] = input1;
          }
        });
        if (validationType1) {
          return this.setValidation(false, validationType1);
        }
      }
      if (this.includeGeoLocation) {
        let valid1 = this.geoLocation && this.geoLocation.lat && this.geoLocation.lon;
        let message1;
        if (valid1) {
          location1["geo_location"] = this.geoLocation;
          this.args.field.value = location1;
        } else {
          message1 = "geo_location";
        }
        return this.setValidation(valid1, message1);
      } else {
        this.args.field.value = location1;
        return this.setValidation(true);
      }
    }
    setValidation(valid1, type1) {
      const message1 = type1 ? _I18n.default.t(`location.validation.${type1}`) : "";
      this.args.field.setValid(valid1, message1);
      return valid1;
    }
    setGeoLocation(gl1) {
      this.name = gl1.name;
      this.street = gl1.street;
      this.neighbourhood = gl1.neighbourhood;
      this.postalcode = gl1.postalcode;
      this.city = gl1.city;
      this.state = gl1.state;
      this.geoLocation = {
        lat: gl1.lat,
        lon: gl1.lon
      };
      this.countrycode = gl1.countrycode;
      this.rawLocation = gl1.address;
    }
    static #_10 = (() => dt7948.n(this.prototype, "setGeoLocation", [_object.action]))();
    searchError(error1) {
      this.flash = error1;
    }
    static #_11 = (() => dt7948.n(this.prototype, "searchError", [_object.action]))();
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <LocationForm
          @street={{this.street}}
          @neighbourhood={{this.neighbourhood}}
          @postalcode={{this.postalcode}}
          @city={{this.city}}
          @state={{this.state}}
          @countrycode={{this.countrycode}}
          @geoLocation={{this.geoLocation}}
          @rawLocation={{this.rawLocation}}
          @inputFields={{this.inputFields}}
          @searchOnInit={{this.searchOnInit}}
          @setGeoLocation={{this.setGeoLocation}}
          @searchError={{this.searchError}}
        />
      
    */
    {
      "id": "unoWbN/0",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[30,0,[\"setGeoLocation\"]],[30,0,[\"searchError\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location.js",
      "scope": () => [_locationForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomWizardFieldLocationComponent;
});